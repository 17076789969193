import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tm-notifications2',
  templateUrl: './tm-notifications2.component.html',
  styleUrls: ['./tm-notifications2.component.scss']
})
export class TmNotifications2Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
