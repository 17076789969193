<div class="sidebar-panel">
    <div id="scroll-area"
         [perfectScrollbar]
         class="navigation-hold"
         fxLayout="column">
        <div class="sidebar-hold">
            <!-- App Logo -->
            <div class="branding">
                <img src="assets/images/common/mmentors-menu-logo.svg"
                     alt=""
                     *ngIf="!layoutConf.sidebarCompactToggle"
                     class="app-logo">
                <img src="assets/images/common/mm-logo.svg"
                     alt=""
                     *ngIf="layoutConf.sidebarCompactToggle"
                     class="app-logo">
            </div>
            <!-- Navigation -->
            <app-sidenav [items]="menuItems"
                         [hasIconMenu]="hasIconTypeMenuItem"
                         [iconMenuTitle]="iconTypeMenuTitle"
                         [sidebarType]="layoutConf"></app-sidenav>
        </div>
    </div>
</div>