import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})

export class SEOService {

  constructor(private title: Title, private meta: Meta) { }

  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc || '' });
  }

  updateOgTitle(title: string) {
    this.meta.updateTag({ property: 'og:title', content: title || '' });
  }

  updateOgDescription(desc: string) {
    this.meta.updateTag({ property: 'og:description', content: desc || '' });
  }

  updateOgImage(imageUrl: string) {
    this.meta.updateTag({ property: 'og:image', content: imageUrl || '' });
  }

  updateOgUrl(url: string) {
    this.meta.updateTag({ property: 'og:url', content: url || window.location.href });
  }

}
