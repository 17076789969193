import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { environment } from 'environments/environment';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';


@Injectable()
export class ForgetPasswordService {


  constructor(public http: HttpClient) {
  }

  public getVerificationCode(email) {
    return this.http.post(`${environment.apiURL}admin/verifyEmail`, { email })
      .pipe(
        map((res: any) => {
          if (res) {
            return res;
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
