import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ILayoutConf, LayoutService } from './layout.service';
import { FormGroup, FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})

export class AppSnackBarService {

  layoutConf: ILayoutConf;
  constructor(private snackBar: MatSnackBar, public layout: LayoutService) {
    this.layoutConf = this.layout.layoutConf;
  }

  // call snackBar service in your component
  /*this.appSnackBarService.showSnackbar
                             ('Snackbar Success', // content
                             'success', //snackBar Type eg:'success','error','warning','info'
                              "ok",  //action button text
                              2000,  //duration seconds
                              'top', // VerticalPosition Allowed values are  'top' | 'bottom'
                              'end', // HorizontalPosition Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
                              this.actionValue.bind(this)); // callback function
  // Action button callback value
   public actionValue(data) {
    if (data) { this.showSnackbar('SUCCESS', 'success'); }
  } */

  public showSnackbar(content: string, snackBarType?: any, action?: any, duration?: any, vPosition?: any, hPosition?: any, callback?) {
    let snackBar = this.snackBar.open(content, action, {
      duration: duration ? duration : this.layoutConf.snackBarDuration,
      panelClass: this.snackBarTypeMethod(snackBarType),
      // VerticalPosition Allowed values are 'top' | 'bottom'
      verticalPosition: vPosition ? vPosition : this.layoutConf.snackBarVerticalPosition,
      // HorizontalPosition Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
      horizontalPosition: hPosition ? hPosition : this.layoutConf.snackBarHorizontalPosition
    });
    snackBar.onAction().subscribe(() => {
      snackBar.dismiss();
      console.log("This will be called when snackbar button clicked");
      if (callback) {
        return callback(true);
      }
    });
    snackBar.afterDismissed().subscribe(() => {
      console.log("This will be shown after snackbar disappeared");
      if (callback) {
        return callback(false);
      }
    });
  }

  public snackBarTypeMethod(snackBarType) {
    switch (snackBarType) {
      case 'success': return "success";
      case 'warning': return "warning";
      case 'info': return "info";
      case 'error': return "error";
      default: return;
    }
  }

  touchAllFields(formGroup: FormGroup): any {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);

      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.touchAllFields(control);
      }
    });
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control && control.value && control.value.toString() || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
}
