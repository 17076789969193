import { Component, OnInit, EventEmitter, Input, ViewChildren, Output, Renderer2 } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { LayoutService } from '../../services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { JwtAuthService } from '../../services/auth/jwt-auth.service';
import { TmNotifications2Component } from '../tm-notifications2/tm-notifications2.component';
import { ApiCallService } from 'app/shared/services/common/api.service';
import { CommonService } from 'app/shared/services/common/common.service';

@Component({
  selector: 'app-header-side',
  templateUrl: './header-side.template.html'
})
export class HeaderSideComponent implements OnInit {
  @Input() notificPanel;
  @ViewChildren(TmNotifications2Component) noti;
  public availableLangs = [{
    name: 'EN',
    code: 'en',
    flag: 'flag-icon-us'
  }, {
    name: 'ES',
    code: 'es',
    flag: 'flag-icon-es'
  }];
  currentLang = this.availableLangs[0];

  public tmThemes;
  public layoutConf: any;
  public userImage: string;
  public userDetail: any;
  constructor(
    private themeService: ThemeService,
    private layout: LayoutService,
    public translate: TranslateService,
    private renderer: Renderer2,
    public jwtAuth: JwtAuthService,
    private apiCallService: ApiCallService,
    public commonService: CommonService,
  ) { }
  ngOnInit() {
    this.tmThemes = this.themeService.tmThemes;
    this.layoutConf = this.layout.layoutConf;
    this.translate.use(this.currentLang.code);
    this.userImage = (this.jwtAuth && this.jwtAuth.user && this.jwtAuth.user.photo) ? this.jwtAuth.user.photo : 'assets/images/common/mmentors-logo.png';
    this.apiCallService.getUserInfoData.subscribe((data) => {
      if (data) {
        // this.getUserDetails();
      }
    })
  }
  setLang(lng) {
    this.currentLang = lng;
    this.translate.use(lng.code);
  }
  changeTheme(theme) {
    // this.themeService.changeTheme(theme);
  }
  toggleNotific() {
    this.notificPanel.toggle();
  }
  oldtoggleSidenav() {
    if (this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      });
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    });
  }
  toggleSidenav() {
    let sideBarCompact = this.layoutConf.sidebarCompactToggle ? false : true;
    if (this.layoutConf.sidebarStyle === 'compact') {
      this.layout.publishLayoutChange({ sidebarStyle: 'full', sidebarCompactToggle: sideBarCompact }, { transitionClass: true });
    } else {
      this.layout.publishLayoutChange({ sidebarStyle: 'compact', sidebarCompactToggle: sideBarCompact }, { transitionClass: true });
    }
  }


  toggleCollapse() {
    // compact --> full
    if (this.layoutConf.sidebarStyle === 'compact') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full',
        sidebarCompactToggle: false
      }, { transitionClass: true });
    }

    // * --> compact
    this.layout.publishLayoutChange({
      sidebarStyle: 'compact',
      sidebarCompactToggle: true
    }, { transitionClass: true });

  }

  onSearch(e) {
    //   console.log(e)
  }
}
