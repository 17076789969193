import { Component, OnInit, Input } from '@angular/core';
import { LayoutService } from 'app/shared/services/layout.service';
import { AppComfirmComponent } from 'app/shared/services/app-confirm/app-confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.template.html'
})
export class SidenavComponent {
  @Input('items') public menuItems: any[] = [];
  @Input('hasIconMenu') public hasIconTypeMenuItem: boolean;
  @Input('iconMenuTitle') public iconTypeMenuTitle: string;
  @Input('sidebarType') public sidebarType: any;
  public currentYear = new Date().getFullYear();
  public layoutData: any;

  constructor(
    private layout: LayoutService,
    public dialog: MatDialog,
    public jwtAuth: JwtAuthService,
  ) { }
  ngOnInit() {
    console.log(this.layout.layoutConf)
    this.layout.layoutConf$.subscribe((result: any) => {
      console.log(result);
      this.layoutData = result;
    })
  }

  public logout(): void {
    let dialogRef = this.dialog.open(AppComfirmComponent, {
      width: '480px',
      disableClose: true,
      data: { title: 'Confirm Logout', message: 'Are you sure you want to Logout?' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.jwtAuth.signout();
      }
    })
  }

}
