import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {

  public docType: string;
  constructor(
    public dialogRef: MatDialogRef<PreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    console.log(this.data);
    if (this.data.type == 'image/jpeg' || this.data.type == 'image/jpg' || this.data.type == 'image/png') {
      this.docType = 'image';
    } else {
      this.docType = 'doc';
    }
  }

  close(isFrom?: boolean): void {
    this.dialogRef.close(isFrom);
  }

}
