import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';


@Injectable()
export class GridService {


  constructor(public http:HttpClient) {
  }

  public getData(){
    return this.http.get("./assets/json/mydata.json");
  }
}
