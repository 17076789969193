import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})

export class CommonService {

  // category
  readonly GetCategoryList: string = 'category/list';
  readonly CreateCategory: string = 'category/create';
  readonly DeleteCategory: string = 'category/delete';
  readonly UpdateCategory: string = 'category/update';
  readonly GetAllCategory: string = 'category/all';

  // catalogue
  readonly CatelogueList: string = 'catalogue/list';
  readonly CreateCatelogue: string = 'catalogue/create';
  readonly CreateBulkLibrary: string = 'catalogue/create/bulk';
  readonly UpdateCatelogue: string = 'catalogue/update';
  readonly FileUpload: string = 'upload';
  readonly DeleteCatelogue: string = 'catalogue/delete';

  // user
  readonly UserList: string = 'admin/users';
  readonly UpdateUser: string = 'admin/updateUser';
  readonly DeleteUser: string = 'admin/deleteUser';
  readonly CreateeUser: string = 'user/register';

  //dashboard
  readonly GetDashboardData: string = 'admin/dashboard';

  // CMS
  readonly GetPolicyTerms: string = 'cms/view';
  readonly SavePolicyTerms: string = 'cms/create';
  readonly UpdatePolicyTerms: string = 'cms/update';

  // Contact Us
  readonly ContactUsUserList: string = 'contact/list';

}
