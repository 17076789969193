<!-- <div class="text-center">
    <h6 class="m-0 pb-16" style="white-space: pre-wrap; line-height: 1.4;">{{ title }}</h6>
    <div mat-dialog-content>
        <mat-spinner [style.margin]="'auto'"></mat-spinner>
    </div>
</div> -->


<div class="loading-indicator">
    <mat-spinner [diameter]="diameter"
                 [strokeWidth]="strokeWidth"></mat-spinner>
</div>