import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class TmLibIconService {

  iconList = [
    { 'name': 'tm_icon_monitor', 'fileName': 'monitor.svg' },
    { 'name': 'mm_icon_dashboard', 'fileName': 'dashboard.svg' },
    { 'name': 'mm_icon_user', 'fileName': 'user.svg' },
    { 'name': 'mm_icon_folder', 'fileName': 'folder.svg' },
    { 'name': 'mm_icon_catelogue', 'fileName': 'catelogue.svg' },
    { 'name': 'mm_icon_create', 'fileName': 'create.svg' },
    { 'name': 'mm_icon_filter', 'fileName': 'filter-icon.svg' },
    { 'name': 'mm_icon_list_active', 'fileName': 'list-active.svg' },
    { 'name': 'mm_icon_list_inactive', 'fileName': 'list-inactive.svg' },
    { 'name': 'mm_icon_box_active', 'fileName': 'box-active.svg' },
    { 'name': 'mm_icon_box_inactive', 'fileName': 'box-inactive.svg' },
    { 'name': 'mm_icon_calendar', 'fileName': 'calendar.svg' },
    { 'name': 'mm_icon_video', 'fileName': 'video.svg' },
    { 'name': 'mm_icon_clock', 'fileName': 'clock.svg' },
    { 'name': 'mm_icon_edit', 'fileName': 'grid-edit.svg' },
    { 'name': 'mm_icon_delete', 'fileName': 'grid-delete.svg' },
    { 'name': 'mm_icon_video_count', 'fileName': 'video-count.svg' },
    { 'name': 'mm_icon_audio_count', 'fileName': 'audio-count.svg' },
    { 'name': 'mm_icon_document_count', 'fileName': 'document-count.svg' },
    { 'name': 'mm_icon_arrow_left', 'fileName': 'arrow-left.svg' },
    { 'name': 'mm_icon_add', 'fileName': 'add.svg' },
    { 'name': 'mm_icon_cata_audio', 'fileName': 'cata-audio.svg' },
    { 'name': 'mm_icon_cata_audio_active', 'fileName': 'cata-audio-active.svg' },
    { 'name': 'mm_icon_cata_video', 'fileName': 'cata-video.svg' },
    { 'name': 'mm_icon_cata_video_active', 'fileName': 'cata-video-active.svg' },
    { 'name': 'mm_icon_cate_doc', 'fileName': 'cate-doc.svg' },
    { 'name': 'mm_icon_cate_doc_active', 'fileName': 'cate-doc-active.svg' },
    { 'name': 'mm_icon_upload', 'fileName': 'upload.svg' },
    { 'name': 'mm_icon_info', 'fileName': 'info-square.svg' },
    { 'name': 'mm_icon_audio', 'fileName': 'audio.svg' },
    { 'name': 'mm_icon_close', 'fileName': 'close.svg' },
    { 'name': 'mm_icon_document', 'fileName': 'document.svg' },
    { 'name': 'mm_icon_catalogue-info', 'fileName': 'catalogue-info.svg' },
    { 'name': 'mm_icon_menu_close', 'fileName': 'menu-close.svg' },
    { 'name': 'mm_icon_menu_open', 'fileName': 'menu-open.svg' },
    { 'name': 'mm_icon_logout', 'fileName': 'logout.svg' },
    { 'name': 'mm_icon_search', 'fileName': 'search.svg' },
    { 'name': 'mm_icon_clear_search', 'fileName': 'clear-filter.svg' },
    { 'name': 'mm_icon_signout', 'fileName': 'signout.svg' },
    { 'name': 'mm_icon_menu_toggle', 'fileName': 'menu-toggle.svg' },
    { 'name': 'mm_icon_count', 'fileName': 'count.svg' },
    { 'name': 'mm_terms_condition', 'fileName': 'terms_condition.svg' },
    { 'name': 'mm_privacy_policy', 'fileName': 'privacy-policy.svg' },
    { 'name': 'mm_arrange', 'fileName': 'arrange.svg' },
    { 'name': 'mm_drag_drop', 'fileName': 'drag-drop.svg' },
    { 'name': 'mm_contact_us', 'fileName': 'contact-us.svg' }
  ]

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
  }

  init() {
    this.iconList.forEach(i => {
      this.matIconRegistry.addSvgIcon(
        i.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/images/svg-icons/${i.fileName}`)
      );
    });
  }
}
