<div class="search-bar-wide "
     [ngClass]="{ open: isOpen }">
  <button mat-icon-button
          class="search-icon-btn ml-8 mr-8"
          (click)="open()">
    <mat-icon>search</mat-icon>
  </button>
  <div>
    <input [formControl]="searchCtrl"
           class="default-bg"
           type="text"
           [placeholder]="placeholder" />
  </div>
  <mat-icon class="search-close text-muted cursor-pointer"
            (click)="close()">close</mat-icon>
</div>