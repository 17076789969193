import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse
} from "@angular/common/http";
import { Observable } from "rxjs";
import { JwtAuthService } from "../services/auth/jwt-auth.service";
import { tap, catchError } from "rxjs/operators";
import { AppSnackBarService } from "../services/snackBar.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  public apiStatus;
  constructor(private jwtAuth: JwtAuthService, private appSnackBarService: AppSnackBarService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    var token = this.jwtAuth.token || this.jwtAuth.getJwtToken();

    var changedReq;

    if (token) {

      changedReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        },
      });

    } else {

      changedReq = req;
    }
    // return next.handle(changedReq);
    return next.handle(changedReq)
      .pipe(catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.handle401Error(changedReq, next, err);
          } else if (err.status === 400) {
            this.handle400Error(err)
          } else if (err.status === 500) {
            this.handle500Error(err)
          } else if (err.status === 429) {
            this.handle429Error(err)
          }
        }
        return new Observable<HttpEvent<any>>();
      }));
  }
  private handle401Error(request: HttpRequest<any>, next: HttpHandler, err) {
    this.appSnackBarService.showSnackbar(err.error.message, 'error', "", 2000, 'top', 'end',);
    if (err.error.errorType == 'TokenExpiredError' || err.error.errorType == 'JsonWebTokenError') {
      // Call Refresh Token
      this.jwtAuth.signout();
    }
  }
  private handle500Error(err) {
    this.appSnackBarService.showSnackbar(err.error.message ? err.error.message : 'Please try again later', 'error', "", 2000, 'top', 'end',);
  }
  private handle400Error(err) {
    this.appSnackBarService.showSnackbar(err.error.message, 'error', "", 2000, 'top', 'end',);
  }
  private handle429Error(err) {
    this.appSnackBarService.showSnackbar('Too many requests from this IP, please try again in an hour!', 'error', "", 2000, 'top', 'end',);
  }
}
