import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-confirm',
  template: `<h1 matDialogTitle class="confirm-header"><mat-icon class="close-icon confirm-close" (click)="dialogRef.close(false)">close</mat-icon> <span class="confirmTitle">{{ data.title }}</span></h1>
    <div mat-dialog-content class="mb-16 confirmContent">{{ data.message }}</div>
    <div mat-dialog-actions>
    <div fxLayout="row wrap" class="width100"
     fxLayout.xs="column">
      <div fxFlex="50%;"
          fxLayoutAlign="end end"
          fxFlex.xs="100%">&nbsp;
        </div>
        <div fxFlex="50%;" fxLayoutAlign="end end"
          fxFlex.xs="100%">
          <button 
            type="button"
            mat-raised-button 
            (click)="dialogRef.close(false)">Cancel</button>
          <button 
              type="button" 
              mat-raised-button
              color="warn" 
              (click)="dialogRef.close(true)">OK</button>
         </div>
     </div>
    `,
})
export class AppComfirmComponent {
  constructor(
    public dialogRef: MatDialogRef<AppComfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
}