import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AppLoaderService } from './app-loader.service';

@Component({
  selector: 'app-app-loader',
  templateUrl: './app-loader.component.html',
  styleUrls: ['./app-loader.component.css']
})
export class AppLoaderComponent implements OnInit {
  // title;
  // message;
  // constructor(public dialogRef: MatDialogRef<AppLoaderComponent>) {}

  // ngOnInit() {
  // }

  @Input() diameter: number = 70;
  @Input() strokeWidth: number = 6;

  constructor(
    public loaderService: AppLoaderService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() { }

  ngAfterViewChecked() {
    // this.loaderService.isLoaded.subscribe((isLoaded) => {
    this.cdr.detectChanges();
    // });
  }

}
