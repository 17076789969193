import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { delay, dematerialize, finalize, materialize } from "rxjs/operators";
import { AppLoaderService } from "../services/app-loader/app-loader.service";

@Injectable()

export class HttpLoaderInterceptor implements HttpInterceptor {
    public service_count = 0; // counter globally initialized.
    exclusions = [".svg", ".json", "upload"];
    constructor(private loaderService: AppLoaderService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.exclusions.some(fragment => req.url.includes(fragment))) {
            this.service_count++; // increment count for each intercepted http request.
            this.loaderService.onStarted();
            return next.handle(req).pipe(materialize())
                .pipe(delay(1000))
                .pipe(dematerialize())
                .pipe(
                    finalize(() => {
                        this.service_count--;
                        // decreament when service is completed (success/failed both
                        //   handled when finalize rxjs operator used)
                        if (this.service_count === 0) {
                            // hide spinner
                            this.loaderService.onFinished();
                        }
                    })
                );
        } else {
            return next.handle(req);
        }
    }
}
