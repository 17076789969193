import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import 'rxjs/add/operator/map';
import { catchError } from 'rxjs/operators';
import { CommonService } from './common.service';
import { environment } from 'environments/environment';
import { JwtAuthService } from '../auth/jwt-auth.service';

@Injectable({
  providedIn: 'root'
})

export class ApiCallService {

  apiIssueMsg: any;
  private userInfoData = new BehaviorSubject({});
  private updateParticipantList = new BehaviorSubject({});
  public getUserInfoData = this.userInfoData.asObservable();
  public getPrticipantList = this.updateParticipantList.asObservable();

  constructor(public http: HttpClient,
    public authService: JwtAuthService,
    public commonService: CommonService) { }

  public sendProfileEvent(data: any): any {
    this.userInfoData.next(data);
  }

  public sendParticipantRefreshEvent(data: any): any {
    this.updateParticipantList.next(data);
  }

  getImage(imgURL: string): Observable<any> {
    return this.http.get(imgURL, { responseType: 'blob' as 'json' }).map((res: any) => res)
  }

  getBlobResponse(url): Observable<any> {
    return this.http.get(environment.apiURL + url, { responseType: 'blob' as 'json' }).map((res: any) => res)
  }

  getFile(fileURL: string): Observable<any> {
    return this.http.get(fileURL, { responseType: 'blob' as 'json' }).map((res: any) => res)
  }

  public createHttp(method: string, url: string, payload?: any, params?: any, mock?: boolean): Observable<any> {
    let endpoint: string = environment.apiURL;
    if (mock) {
      endpoint = '';
    }
    switch ((method).toLowerCase()) {
      case 'get': return this.createHttpGet(endpoint + url, params);
      case 'post': return this.createHttpPost(endpoint + url, payload);
      case 'put': return this.createHttpPut(endpoint + url, payload);
      case 'patch': return this.createHttpPatch(endpoint + url, payload);
      case 'delete': return this.createHttpDelete(endpoint + url, params);
      case 'file': return this.getFile(endpoint + url);
      default: return this.http.get('');
    }
  }

  private errorHandling(err: HttpErrorResponse): void {
    if (err.status === 401) {
      this.authService.signout();
    } else {
      // tslint:disable-next-line: no-console
      console.error(err);
    }
  }

  /**
  * This GET method
  * @param url The URL to hit
  * @param parameters The Request params
  */
  private createHttpGet(url: string, parameters: HttpParams): Observable<Object> {
    const reqParams: HttpParams | { [param: string]: string | string[] } = {
      ...parameters,
    };

    return this.http
      .get(url, { observe: 'response', params: reqParams })
      .pipe(
        // tslint:disable-next-line: no-any
        catchError((err: HttpErrorResponse): any => {
          this.errorHandling(err);
        }),
      );
  }

  /**
  * This Delete method
  * @param url The URL to hit
  * @param parameters The Request params
  */
  private createHttpDelete(url: string, params: HttpParams): Observable<Object> {
    return this.http.request('delete', url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: params,
    }).pipe(
      // tslint:disable-next-line: no-any
      catchError((err: HttpErrorResponse): any => {
        this.errorHandling(err);
      }),
    );
  }

  /**
  * This POST sends REQUEST as plain/text
  * @param url The URL to hit
  * @param payload The Request payload
  */
  private createHttpPost(url: string, payload: HttpParams): Observable<Object> {
    return this.http
      .post(url, payload)
      .pipe(
        // tslint:disable-next-line: no-any
        catchError((err: HttpErrorResponse): any => {
          this.errorHandling(err);
        }),
      );
  }

  /**
  * This PUT method
  * @param url The URL to hit
  * @param payload The Request payload
  */
  private createHttpPut(url: string, payload: HttpParams): Observable<Object> {
    return this.http
      .put(url, payload)
      .pipe(
        // tslint:disable-next-line: no-any
        catchError((err: HttpErrorResponse): any => {
          this.errorHandling(err);
        }),
      );
  }

  /**
  * This PATCH method
  * @param url The URL to hit
  * @param payload The Request payload
  */
  private createHttpPatch(url: string, payload: HttpParams): Observable<Object> {
    return this.http
      .patch(url, payload)
      .pipe(
        // tslint:disable-next-line: no-any
        catchError((err: HttpErrorResponse): any => {
          this.errorHandling(err);
        }),
      );
  }
}
