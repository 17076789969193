<mat-nav-list>
  <mat-list-item>
      <svg class="icon-bottom-sheet icon-facebook">
          <use xlink:href="#icon-facebook" />
      </svg>
      <a mat-list-item href="#0">
        <span mat-line>Share on Facebook</span>
      </a>
  </mat-list-item>
  <mat-list-item>
      <svg class="icon-bottom-sheet icon-facebook">
          <use xlink:href="#icon-twitter" />
      </svg>
      <a mat-list-item href="#0">
        <span mat-line>Tweet About Us!</span>
      </a>
  </mat-list-item>
  <mat-list-item>
      <svg class="icon-bottom-sheet icon-linkedin">
        <use xlink:href="#icon-linkedin" />
      </svg>
      <a mat-list-item href="#0">
        <span mat-line>Share on LinkedIn</span>
      </a>
  </mat-list-item>
</mat-nav-list>
