import { Component, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import { NavigationService } from "../../../shared/services/navigation.service";
import { ThemeService } from "../../services/theme.service";
import { Subscription } from "rxjs";
import { ILayoutConf, LayoutService } from "app/shared/services/layout.service";
import { JwtAuthService } from "app/shared/services/auth/jwt-auth.service";

@Component({
  selector: "app-sidebar-side",
  templateUrl: "./sidebar-side.component.html"
})
export class SidebarSideComponent implements OnInit, OnDestroy, AfterViewInit {
  public menuItems: any[] = [];
  public hasIconTypeMenuItem: boolean;
  public iconTypeMenuTitle: string;
  private menuItemsSub: Subscription;
  public layoutConf: ILayoutConf;

  constructor(
    private navService: NavigationService,
    public themeService: ThemeService,
    private layout: LayoutService,
    public jwtAuth: JwtAuthService
  ) { }

  ngOnInit() {
    this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;
    this.menuItemsSub = this.navService.menuItems$.subscribe(menuItem => {
      if (menuItem) {
        const userRole = localStorage.getItem('userRole');
        let adminmenuAvailble: boolean = false;
        menuItem.forEach(item => {
          if (item.name == 'Manage Admin Users') {
            adminmenuAvailble = true;
          }
        })
        console.log(userRole);
        if ((userRole == 'SUPERADMIN' || userRole == 'MASTERADMIN') && adminmenuAvailble == false) {
          menuItem.push(
            {
              name: 'Manage Admin Users',
              type: 'link',
              svgIcon: 'bbrun_icon_manage_admin',
              state: 'admin/manage-admin-user'
            },
          );
          this.menuItems = menuItem;
        } else if (userRole != 'SUPERADMIN' && userRole != 'MASTERADMIN') {
          this.menuItems = menuItem;
          if (this.menuItems.length > 11) {
            this.menuItems.splice(-1, 1);
          }
        } else {
          this.menuItems = menuItem;
        }
        //Checks item list has any icon type.
        this.hasIconTypeMenuItem = !!this.menuItems.filter(
          item => item.type === "icon"
        ).length;
      }
    });
    this.layoutConf = this.layout.layoutConf;
  }
  ngAfterViewInit() { }
  ngOnDestroy() {
    if (this.menuItemsSub) {
      this.menuItems = [];
      this.menuItemsSub.unsubscribe();
    }
  }
  toggleCollapse() {
    if (
      this.layoutConf.sidebarCompactToggle
    ) {
      this.layout.publishLayoutChange({
        sidebarCompactToggle: false
      });
    } else {
      this.layout.publishLayoutChange({
        // sidebarStyle: "compact",
        sidebarCompactToggle: true
      });
    }
  }
}
