import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FontSizeDirective } from './font-size.directive';
import { ScrollToDirective } from './scroll-to.directive';
import { AppDropdownDirective } from './dropdown.directive';
import { DropdownAnchorDirective } from './dropdown-anchor.directive';
import { DropdownLinkDirective } from './dropdown-link.directive';
import { TmSideNavToggleDirective } from './tm-side-nav-toggle.directive';
import { TmSidenavHelperDirective, TmSidenavTogglerDirective } from './tm-sidenav-helper/tm-sidenav-helper.directive';
import { TmHighlightDirective } from './tm-highlight.directive';


const directives = [
  FontSizeDirective,
  ScrollToDirective,
  AppDropdownDirective,
  DropdownAnchorDirective,
  DropdownLinkDirective,
  TmSideNavToggleDirective,
  TmSidenavHelperDirective,
  TmSidenavTogglerDirective,
  TmHighlightDirective
]

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: directives,
  exports: directives
})
export class SharedDirectivesModule {}
