import { Component, OnInit, AfterViewInit, Renderer2, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { RoutePartsService } from './shared/services/route-parts.service';
import { filter } from 'rxjs/operators';
import { TmLibIconService } from './shared/services/tm-lib-icon.service';
import { AppLoaderService } from './shared/services/app-loader/app-loader.service';
import { SEOService } from './shared/services/seo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  appTitle = 'Mango Mentors';
  pageTitle = '';
  showLoader: boolean = false;

  constructor(
    public seoService: SEOService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private routePartsService: RoutePartsService,
    private loaderService: AppLoaderService,
    private iconService: TmLibIconService,
    private cdr: ChangeDetectorRef,
  ) {
    iconService.init()
  }

  ngOnInit() {
    this.changePageTitle();
  }

  ngAfterViewInit() {
  }

  ngAfterViewChecked() {
    this.loaderService.isLoaded.subscribe((isLoaded) => {
      this.showLoader = isLoaded;
      this.cdr.detectChanges();
    })
  }


  changePageTitle() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange) => {
      const routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
      if (!routeParts.length) {
        return this.seoService.updateTitle(this.appTitle);
      }
      // Extract title from parts;
      this.pageTitle = routeParts
        .reverse()
        .map((part) => part.title)
        .reduce((partA, partI) => { return `${partA} > ${partI}` });
      this.pageTitle += ` | ${this.appTitle}`;
      this.seoService.updateTitle(this.pageTitle);
    });
  }
}
