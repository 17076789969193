<div fxLayout="row wrap"
     fxLayout.xs="column"
     class="mb-2">
     <div fxFlex="50%;"
          fxFlex.xs="100%">
          <div class="header">Preview</div>
     </div>
     <div fxFlex="50%;"
          fxFlex.xs="100%"
          fxLayoutAlign="end end">
          <mat-icon class="cursor-pointer"
                    (click)="close(false)">close</mat-icon>
     </div>
</div>

<div fxLayout="row wrap"
     fxLayout.xs="column">
     <div style="width: 100%;"
          *ngIf="data.link && docType == 'image'">
          <img [src]="data.link"
               alt="imge"
               style="width:100%;height:auto;object-fit: cover;" />
     </div>
     <div style="width: 100%;"
          *ngIf="data.link && docType == 'doc'">
          <ngx-doc-viewer [url]="data.link"
                          viewer="google"
                          style="width:100%;height:50vh;">
          </ngx-doc-viewer>
     </div>
</div>
<div fxLayout="row wrap"
     fxLayout.xs="column">
</div>