import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { SocialLayoutComponent } from './shared/components/layouts/social-layout/social-layout.component';

export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: 'admin/dashboard', // dashboard
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Session' }
      }
    ]
  },
  {
    path: '',
    component: SocialLayoutComponent,
    children: [
      {
        path: 'event',
        loadChildren: () => import('./views/social-share/social-share.module').then(m => m.SocialShareModule),
        data: { title: 'Social Share', breadcrumb: 'Social' }
      },
      {
        path: 'applink',
        loadChildren: () => import('./views/app-download/app-download.module').then(m => m.AppDownloadModule),
        data: { title: 'Download', breadcrumb: 'Download' }
      },
      {
        path: 'privacy-policy',
        loadChildren: () => import('./views/view-privacy-policy/view-privacy-policy.module').then(m => m.ViewPrivacyPolicyModule),
        data: { title: 'Privacy Policy', breadcrumb: 'Privacy Policy' }
      },
      {
        path: 'terms-condition',
        loadChildren: () => import('./views/view-terms-condition/view-terms-condition.module').then(m => m.ViewTermsConditionModule),
        data: { title: 'Terms & Condition', breadcrumb: 'Terms & Condition' }
      },
    ]
  },

  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'others',
        loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule),
        data: { title: 'Others', breadcrumb: 'OTHERS' }
      },
      {
        path: 'admin',
        loadChildren: () => import('./views/admin/admin.module').then(m => m.AdminModule),
        data: { title: 'Admin', breadcrumb: 'Admin' }
      },
      {
        path: 'grid',
        loadChildren: () => import('./views/app-grid/app-grid.module').then(m => m.AppGridModule),
        data: { title: 'App Grid', breadcrumb: 'GRID' }
      },
      {
        path: 'search',
        loadChildren: () => import('./views/search-view/search-view.module').then(m => m.SearchViewModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

