<mat-toolbar class="topbar mat-bg-card">
  <!-- Sidenav toggle button -->
  <button mat-icon-button
          id="sidenavToggle"
          (click)="toggleSidenav()"
          matTooltip="Toggle Hide/Open">
    <!-- <mat-icon class="menu-close">menu</mat-icon> -->
    <mat-icon svgIcon="mm_icon_menu_close"
              *ngIf="layoutConf.sidebarStyle == 'full' || layoutConf.sidebarStyle == 'closed'"
              class="svgIcon cursor-pointer menu-icon-container"> </mat-icon>
    <mat-icon svgIcon="mm_icon_menu_open"
              *ngIf="layoutConf.sidebarStyle == 'compact'"
              class="svgIcon cursor-pointer menu-icon-container"> </mat-icon>
  </button>
  <!-- <span class="divder"></span> -->
  <!-- <div class="appTitle">Mango Mentors</div> -->

  <span fxFlex></span>
  <mat-menu #menu="matMenu">
    <button mat-menu-item
            *ngFor="let lang of availableLangs"
            (click)="setLang(lang)">
      <span class="flag-icon mr-8 {{lang.flag}}"></span>
      <span>{{lang.name}}</span>
    </button>
  </mat-menu>


  <!-- Top left user menu -->
  <div class="sign-out">
    <button mat-menu-item
            (click)="jwtAuth.signout()">
      <mat-icon svgIcon="mm_icon_logout"
                class="svgIcon cursor-pointer logout-icon"> </mat-icon>
    </button>
  </div>
  <p class="userDetail"
     *ngIf="userDetail"><span class="userName">{{userDetail.name}}</span> <span
          class="userRole">{{userDetail.userType}}</span></p>
  <button mat-icon-button
          class="topbar-button-right img-button">
    <img [src]=userImage
         class="userProfileImage"
         alt="" />
  </button>

  <mat-menu #accountMenu="matMenu">
    <button mat-menu-item
            (click)="jwtAuth.signout()">
      <mat-icon>exit_to_app</mat-icon>
      <span>{{ "SIGNOUT" | translate }}</span>
    </button>
  </mat-menu>
</mat-toolbar>