import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AppLoaderComponent } from './app-loader.component';

interface Config {
  width?: string
}

@Injectable()
export class AppLoaderService {
  // dialogRef: MatDialogRef<AppLoaderComponent>;
  // constructor(private dialog: MatDialog) { }

  // public open(title: string = 'Please wait', config: Config = {width: '200px'}): Observable<boolean> {
  //   this.dialogRef = this.dialog.open(AppLoaderComponent, { disableClose: true, backdropClass: 'light-backdrop'});
  //   this.dialogRef.updateSize(config.width);
  //   this.dialogRef.componentInstance.title = title;
  //   return this.dialogRef.afterClosed();
  // }

  // public close() {
  //   if (this.dialog) {
  //     this.dialog.closeAll();
  //   }
  // }

  constructor() { }
  private _isLoaded: Subject<boolean> = new BehaviorSubject<boolean>(false);

  public get isLoaded(): Observable<boolean> {
    return this._isLoaded.asObservable();
  }

  public onStarted() {
    this._isLoaded.next(true);
  }

  public onFinished() {
    this._isLoaded.next(false);
  }

}
